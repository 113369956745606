import { FetchUtils } from "../../utils/fetch.utils";
import { Report } from "../models/report";
import { ReportType } from "../models/reportType";
import { ReportCategory } from "../models/reportCategory";
import { UserReportHistory } from "../models/userReportHistory";
import { ExportFileExtension } from "../models/exportFileExtension";
import appConfig from "../../utils/appConfig";
import { StartExportModel } from "../models/startExportModel";
import { ShareReportUser } from "../models/shareReport";
import { UserReportHistoryExportShare } from "../models/UserReportHistoryExportShare";

const apiBaseUrl = appConfig.apiBaseUrl;
const reportApi = 'report';

const getReports = (getRunDates?: boolean): Promise<Report[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetReports${getRunDates ? `?getRunDates=${getRunDates}` : ''}`;
  let response = FetchUtils.GET<Report[]>(URL);
  return response;
};

const runReportByReportId = (reportId: number): Promise<string> => {
  const URL = `${apiBaseUrl}/${reportApi}/RunPowerBIReportByReportId?reportId=${reportId}`;
  let response = FetchUtils.GET<string>(URL);
  return response;
};

const exportReport = (
  reportId: number,
  embedUrl: string,
  extensions: string[]
): Promise<number> => {
  //const URL = `${apiBaseUrl}/${reportApi}/StartExportPowerBIReport?reportId=${reportId}&embedUrl=${embedUrl}&fileExtension=${extensions.join(',')}`;
  const URL = `${apiBaseUrl}/${reportApi}/StartExportPowerBIReport`;
  let g = embedUrl.substr(embedUrl.indexOf('filter=') + 'filter='.length);
  let response = FetchUtils.POST<number>(URL, { reportId, filterUrl: g, fileExtension: extensions[0] } as StartExportModel);
  return response;
};

const getReportTypes = (): Promise<ReportType[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetActiveReportTypes`;
  let response = FetchUtils.GET<ReportType[]>(URL);
  return response;
};

const getReportCategories = (): Promise<ReportCategory[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetActiveReportCategories`;
  let response = FetchUtils.GET<ReportCategory[]>(URL);
  return response;
};

const getReportById = (reportId: number): Promise<Report> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetReportByReportId?reportId=${reportId}`;
  let response = FetchUtils.GET<Report>(URL);
  return response;
};

const getUserReportHistory = (): Promise<UserReportHistory[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetUserReportHistory`;
  let response = FetchUtils.GET<UserReportHistory[]>(URL);
  return response;
};
const getUserReportHistoryByToken = (urltoken:string): Promise<UserReportHistory[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetUserReportShare?urlToken=${urltoken}`;
  let response = FetchUtils.GET<UserReportHistory[]>(URL);
  return response;
};
const getUserReportHistoryBySubscriptionId = (subscriptionId?: number): Promise<UserReportHistory[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetUserReportHistoryBySubscriptionId${subscriptionId ? `?subscriptionId=${subscriptionId}` : ''}`;
  let response = FetchUtils.GET<UserReportHistory[]>(URL);
  return response;
};

const getExportFileExtensions = (): Promise<ExportFileExtension[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetExportFileExtensions`;
  let response = FetchUtils.GET<ExportFileExtension[]>(URL);
  return response;
};

const setFavoriteReport = (reportId: number): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${reportApi}/SetFavoriteReport?reportId=${reportId}`;
  let response = FetchUtils.PUT<boolean>(URL);
  return response;
};

const addUserReportShare = (reports: ShareReportUser): Promise<Report[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/AddUserReportShare`;
  let response = FetchUtils.POST<Report[]>(URL, reports);
  return response;
};

const emailReportShare = (reports: ShareReportUser): Promise<Report[]> => {
  const URL = `${apiBaseUrl}/${reportApi}/EmailUserReportShare`;
  let response = FetchUtils.POST<Report[]>(URL, reports);
  return response;
};

const getReportSharedUsers = (urltoken:string): Promise<UserReportHistoryExportShare[]> => {
    const URL = `${apiBaseUrl}/${reportApi}/GetReportSharedUsers?urlToken=${urltoken}`;
    let response = FetchUtils.GET<UserReportHistoryExportShare[]>(URL);
    return response;
  };

const removeReportShareUser = (userId: number, userReportHistoryExportShareId: number): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${reportApi}/RemoveReportShareUser?userId=${userId}&userReportHistoryExportShareId=${userReportHistoryExportShareId}`;
  let response = FetchUtils.DELETE<boolean>(URL);
  return response;
}

export const reportService = {
  getReports,
  runReportByReportId,
  exportReport,
  getReportTypes,
  getReportCategories,
  getReportById,
  getUserReportHistory,
  getUserReportHistoryByToken,
  getUserReportHistoryBySubscriptionId,
  getExportFileExtensions,
  setFavoriteReport,
  addUserReportShare,
  emailReportShare,
  getReportSharedUsers,
  removeReportShareUser
};
