import { ReportActions } from "../actions/report.actions";
import { Report, CreateDefaultReport } from "../models/report";
import { ReportType } from "../models/reportType";
import { ReportCategory, CreateDefaultReportCategory } from "../models/reportCategory";
import { UserReportHistory } from "../models/userReportHistory";
import { ExportFileExtension } from "../models/exportFileExtension";
import { models } from "powerbi-client";
import { Filter, CreateReportFilters, CreateFileStorageFilters } from "../models/filter";
import { NetworkError, ServerError } from "../models/serverError";
import { FilteredReport } from "../selectors/report.selector";
import { FilteredFileStorage } from "../selectors/history.selector";
import { UserReportHistoryExportShare } from "../models/UserReportHistoryExportShare";

type ReportState = {
    reports: Report[];
    reportTypes: ReportType[];
    reportCategories: ReportCategory[];
    selectedCategory?: ReportCategory;
    sortField: string;
    sortDirection: "asc" | "desc";
    reportHistorySortField: string;
    reportHistorySortDirection: "asc" | "desc";
    exportExtensions: ExportFileExtension[];
    selectedExtension?: ExportFileExtension;
    loading: boolean;
    deleteLoading?: boolean;
    loadingAdd: boolean;
    loadingUpdate: boolean;
    loadingDelete: boolean;
    startExportLoading: boolean;
    isCurrentlyExporting: boolean;
    getExportLoading: Record<string, boolean>;
    getReportLoading: Record<string, boolean>;
    error?: ServerError | NetworkError;
    uniqueEmbedId?: string;
    userReportHistoryId?: number;
    userReportHistoryExportDetailsId?: number;
    embedUrl?: string;
    embedToken?: string;
    status?: string;
    progressComplete?: number;
    reportsLoading: boolean;
    loadingReport: boolean;
    fileName: string;
    blob?: Blob;
    selectedReport: Report | null;
    saved: boolean;
    deleted: boolean;
    userReportHistory: UserReportHistory[];
    userReportHistoryLoading: boolean;
    selectedUserReportHistoryCategory: ReportCategory;
    selectedSubscriptionId?: number;
    reportSearchKeyword: string;
    reportHistorySearchKeyword: string;
    pinFavorites: boolean;
    isRedirect: boolean;
    slicerStates?: models.ISlicer[];
    reportFilters?: models.IFilter[];
    filterPaneOpen: boolean;
    pageFilters: Filter<FilteredReport>[];
    fileStorageFilterPaneOpen: boolean;
    fileStorageFilters: Filter<FilteredFileStorage>[];
    exportExtensionsLoading: boolean;
    isTileView: boolean;
    addUserRptShareLoading:boolean;
    getSharedReportUsersLoading:boolean;
    userReportHistoryExportShare?: UserReportHistoryExportShare;
    reportSharedUsers?: UserReportHistoryExportShare[];

}
const initialState: ReportState = {
    reports: [],
    reportTypes: [],
    reportCategories: [],
    exportExtensions: [],
    selectedExtension: undefined,
    selectedCategory: undefined,
    sortField: "nameLowered",
    sortDirection: "asc",
    reportHistorySortField: "startDateUTC",
    reportHistorySortDirection: "desc",
    loading: false,
    deleteLoading: false,
    loadingAdd: false,
    loadingUpdate: false,
    loadingDelete: false,
    startExportLoading: false,
    isCurrentlyExporting: false,
    getExportLoading: {},
    getReportLoading: {},
    error: undefined,
    uniqueEmbedId: undefined,
    userReportHistoryId: undefined,
    userReportHistoryExportDetailsId: undefined,
    embedUrl: undefined,
    embedToken: undefined,
    status: undefined,
    progressComplete: undefined,
    fileName: '',
    blob: undefined,
    reportsLoading: true,
    loadingReport: false,
    selectedReport: null,
    saved: false,
    deleted:false,
    userReportHistory: [],
    userReportHistoryLoading: false,
    selectedUserReportHistoryCategory: CreateDefaultReportCategory(),
    reportSearchKeyword: "",
    reportHistorySearchKeyword: "",
    pinFavorites: false,
    isRedirect: false,
    slicerStates: undefined,
    reportFilters: undefined,
    filterPaneOpen: false,
    pageFilters: CreateReportFilters(),
    fileStorageFilterPaneOpen: false,
    fileStorageFilters: CreateFileStorageFilters(),
    exportExtensionsLoading: false,
    isTileView: false,
    addUserRptShareLoading:false,
    getSharedReportUsersLoading:false,
    userReportHistoryExportShare:undefined,
    reportSharedUsers:[]
}
const ReportReducer = (state: ReportState = initialState, action: ReportActions): ReportState => {
    switch (action.type) {

        // GET USER REPORTS
        case 'GET_USER_REPORT_REQUEST':
            return {
                ...state,
                loading: true,
                reportsLoading: true,
                blob: undefined,
                progressComplete: undefined,
                status: undefined,
                userReportHistoryId: undefined,
                userReportHistoryExportDetailsId: undefined,
                embedToken: undefined,
                embedUrl: undefined,
                error: undefined,
                saved: false,
                slicerStates: undefined,
                reportFilters: undefined
            }
        case 'GET_USER_REPORT_SUCCESS':
            return {
                ...state,
                reports: action.payload ?? state.reports,
                loading: false,
                error: undefined,
                reportsLoading: false
            }
        case 'GET_USER_REPORT_FAILURE':
            return {
                ...state,
                reports: [],
                loading: false,
                error: action.error,
                reportsLoading: false
            }

        // GET REPORT
        case 'GET_REPORT_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined,
            }
        case 'GET_REPORT_SUCCESS':
            return {
                ...state,
                selectedReport: action.payload ?? state.selectedReport,
                loading: false,
                error: undefined,
            }
        case 'GET_REPORT_FAILURE':
            return {
                ...state,
                reports: [],
                //loading: false,
                error: action.error,
                reportsLoading: false
            }

        // RUN REPORT
        case 'RUN_REPORT_REQUEST':
            return {
                ...state,
                //loading: true,
                error: undefined,
                uniqueEmbedId: undefined,
                userReportHistoryId: undefined,
                userReportHistoryExportDetailsId: undefined,
                startExportLoading: false,
            }
        case 'RUN_REPORT_SUCCESS':
            return {
                ...state,
                //loading: false,
                uniqueEmbedId: action.uniqueEmbedId,
                error: undefined
            }
        case 'RUN_REPORT_FAILURE':
            return {
                ...state,
                //loading: false,
                error: action.error
            }

        // UPDATE REPORT
        case 'UPDATE_REPORT_REQUEST':
            return {
                ...state,
                embedUrl: action.embedURL ?? state.embedUrl,
                embedToken: action.embedToken ?? state.embedToken,
                status: action.status ?? state.status,
                progressComplete: action.progressComplete ?? state.progressComplete,
            }

        // GET EXPORT EXTENSIONS
        case 'GET_EXPORT_EXTENSIONS_REQUEST':
            return {
                ...state,
                error: undefined,
                exportExtensionsLoading: true
            }
        case 'GET_EXPORT_EXTENSIONS_SUCCESS':
            return {
                ...state,
                error: undefined,
                exportExtensions: action.payload ?? state.exportExtensions,
                selectedExtension: action.payload?.[0],
                exportExtensionsLoading: false
            }
        case 'GET_EXPORT_EXTENSIONS_FAILURE':
            return {
                ...state,
                error: action.error,
                exportExtensions: [],
                exportExtensionsLoading: false
            }

        // EXPORT REPORT
        case 'EXPORT_REPORT_REQUEST':
            return {
                ...state,
                startExportLoading: true,
                userReportHistoryExportDetailsId: undefined
            }
        case 'EXPORT_REPORT_SUCCESS':
            return {
                ...state,
                startExportLoading: false,
                isCurrentlyExporting: true,
                userReportHistoryExportDetailsId: action.userReportHistoryExportDetailsId
            }
        case 'EXPORT_REPORT_FAILURE':
            return {
                ...state,
                startExportLoading: false,
                isCurrentlyExporting: false,
                userReportHistoryExportDetailsId: undefined,
            }

        case 'SET_IS_CURRENTLY_EXPORTING':
            return {
                ...state,
                isCurrentlyExporting: action.value
            }

        // GET EXPORT REPORT
        case 'GET_REPORT_TYPE_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case 'GET_REPORT_TYPE_SUCCESS':
            return {
                ...state,
                reportTypes: [...(action.payload ?? state.reportTypes)],
                loading: false,
                error: undefined,
                startExportLoading: false
            }
        case 'GET_REPORT_TYPE_FAILURE':
            return {
                ...state,
                reportTypes: [],
                loading: false,
                error: action.error,
                startExportLoading: false
            }

        // GET REPORT CATEGORIES
        case 'GET_REPORT_CATEGORY_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case 'GET_REPORT_CATEGORY_SUCCESS':
            return {
                ...state,
                reportCategories: [...(action.payload ?? state.reportCategories)],
                loading: false,
                error: undefined,
            }
        case 'GET_REPORT_CATEGORY_FAILURE':
            return {
                ...state,
                reportTypes: [],
                loading: false,
                error: action.error
            }

        // ADD REPORT CATEGORY
        case 'ADD_REPORT_CATEGORY_REQUEST':
            return {
                ...state,
                loadingAdd: true
            }
        case 'ADD_REPORT_CATEGORY_SUCCESS':
            return {
                ...state,
                reportCategories: [...state.reportCategories, action.payload!],
                loadingAdd: false
            }
        case 'ADD_REPORT_CATEGORY_FAILURE':
            return {
                ...state,
                loadingAdd: false,
                error: action.error
            }

        // DELETE
        case 'DELETE_REQUEST':
            return {
                ...state,
                deleteLoading: false
            }
        case 'DELETE_SUCCESS':
            return {
                ...state,
                deleteLoading: action.payload
            }
        case 'DELETE_FAILURE':
            return {
                ...state,
                deleteLoading: false,
                error: action.error
            }

        // UPDATE REPORT CATEGORY
        case 'UPDATE_REPORT_CATEGORY_REQUEST':
            return {
                ...state,
                loadingUpdate: true
            }
        case 'UPDATE_REPORT_CATEGORY_SUCCESS':
            return {
                ...state,
                reportCategories: [...state.reportCategories.map(rc => { return rc.categoryId === action.payload!.categoryId ? { ...action.payload! } : rc })],
                loadingUpdate: false
            }
        case 'UPDATE_REPORT_CATEGORY_FAILURE':
            return {
                ...state,
                loadingUpdate: false,
                error: action.error
            }

        // DELETE REPORT CATEGORY
        case 'DELETE_REPORT_CATEGORY_REQUEST':
            return {
                ...state,
                loadingDelete: true
            }
        case 'DELETE_REPORT_CATEGORY_SUCCESS':
            return {
                ...state,
                reportCategories: [...state.reportCategories.filter(rc => rc.categoryId !== action.reportCategoryId)],
                loadingDelete: false
            }
        case 'DELETE_REPORT_CATEGORY_FAILURE':
            return {
                ...state,
                loadingDelete: false,
                error: action.error
            }

        // GET EXPORTED REPORT
        case 'GET_EXPORTED_REPORT_REQUEST':
            return {
                ...state,
                getExportLoading: { ...state.getExportLoading, [action.userReportHistoryExportDetailsId.toString()]: true },
                error: undefined
            }
        case 'GET_EXPORTED_REPORT_SUCCESS':
            return {
                ...state,
                fileName: action.fileName ?? state.fileName,
                blob: action.blob,
                getExportLoading: { ...state.getExportLoading, [action.userReportHistoryExportDetailsId.toString()]: false },
                isCurrentlyExporting: false,
                error: undefined
            }
        case 'GET_EXPORTED_REPORT_FAILURE':
            return {
                ...state,
                getExportLoading: { ...state.getExportLoading, [action.userReportHistoryExportDetailsId.toString()]: false },
                isCurrentlyExporting: false,
                error: action.error
            }

        // SET CATEGORY
        case 'SET_CATEGORY':
            return {
                ...state,
                selectedCategory: action.category
            }

        // SET REPORT
        case 'SET_REPORT':
            return {
                ...state,
                selectedReport: action.report,
                saved: false
            }


        // SEARCH REPORTS
        case 'SEARCH_REPORTS':
            return {
                ...state,
                reportSearchKeyword: action.search,
            }

        // SEARCH REPORT HISTORY
        case 'SEARCH_REPORT_HISTORY':
            return {
                ...state,
                reportHistorySearchKeyword: action.search,
            }

        //Add Report
        case 'ADD_REPORT_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined,
                saved: false,
            }
        case 'ADD_REPORT_SUCCESS':
            return {
                ...state,
                loading: false,
                error: undefined,
                reports: action.newReport ? [...state.reports, ...action.newReport] : state.reports,
                saved: true,
                isRedirect: !state.isRedirect,
            }
        case 'ADD_REPORT_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error,
                saved: false
            }

        //Update Report
        case 'SAVE_REPORT_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined,
                saved: false
            }
        case 'SAVE_REPORT_SUCCESS':
            return {
                ...state,
                saved: true,
                loading: false,
                error: undefined,
                reports: [...state.reports.flat().filter((r)=>{return r.reportId!=action.report.reportId}).flat(),action.report]
            }
        case 'SAVE_REPORT_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error,
                saved: false
            }

        //Delete Report
        case 'DELETE_REPORT_REQUEST':
            return {
                ...state,
                loading: true,
                deleted:true,
                error: undefined
            }
        case 'DELETE_REPORT_SUCCESS':
            return {
                ...state,
                saved: true,
                deleted:true,
                loading: false,
                error: undefined,
                reports: [...state.reports.flat().map(r => { return action.reportIds.includes(r.reportId) ? { ...r, active: false } : r })]
            }
        case 'DELETE_REPORT_FAILURE':
            return {
                ...state,
                loading: false,
                deleted:true,
                error: action.error,
            }
        // SORT REPORTS
        case 'SORT_REPORTS':
            const { sortField, sortDirection } = state;
            return {
                ...state,
                sortField: action.field,
                sortDirection: sortField === action.field ? (sortDirection === "asc" ? "desc" : "asc") : "asc"
            }

        // GET USER REPORT HISTORY
        case 'GET_USER_REPORT_HISTORY_REQUEST':
            return {
                ...state,
                loading: true,
                userReportHistoryLoading: true,
            }
        case 'GET_USER_REPORT_HISTORY_SUCCESS':
            return {
                ...state,
                loading: false,
                userReportHistoryLoading: false,
                userReportHistory: action.userReportHistory!,
                //filteredUserReportHistory: action.userReportHistory!
            }
        case 'GET_USER_REPORT_HISTORY_FAILURE':
            return {
                ...state,
                loading: false,
                userReportHistoryLoading: false,
                error: action.error
            }

        // USER REPORT HISTORY BY SUBSCRIPTION
        case 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_REQUEST':
            return {
                ...state,
                loading: true,
                userReportHistoryLoading: true,
            }
        case 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_SUCCESS':
            return {
                ...state,
                loading: false,
                userReportHistoryLoading: false,
                userReportHistory: action.userReportHistory!,
                //filteredUserReportHistory: action.userReportHistory!,
            }
        case 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_FAILURE':
            return {
                ...state,
                loading: false,
                userReportHistoryLoading: false,
                error: action.error
            }

        // SORT REPORT HISTORY
        case 'SORT_REPORT_HISTORY':
            const { reportHistorySortField, reportHistorySortDirection } = state;
            const isSortByDirection=action.field=='isRunning'||action.field=='isPartial'||action.field=='isQueued'||action.field=='isComplete';
            return {
                ...state,
                reportHistorySortField: action.field,
                reportHistorySortDirection: (reportHistorySortField === action.field && reportHistorySortDirection === "asc")||isSortByDirection ? "desc" : "asc"
            }

        // SET REPORT HISTORY CATEGORY
        case 'SET_REPORT_HISTORY_CATEGORY':
            return {
                ...state,
                selectedUserReportHistoryCategory: action.category
            }
        // SET USER REPORT HISTORY PROGRESS 
        case 'SET_USER_REPORT_HISTORY_PROGRESS':
            const newUserReportHistory = state.userReportHistory.filter(urh => urh.userReportHistoryId === action.userReportHistoryId)[0];
            let userReportHistoryDetail = newUserReportHistory.userReportHistoryExportDetails.filter(urhd => urhd.userReportHistoryExportDetailsId === action.userReportHistoryExportDetailsId)[0];
            userReportHistoryDetail.percentComplete = action.progressComplete;
            userReportHistoryDetail.exportStatus = action.userReportHistoryExportDetailsStatus;
            return {
                ...state,
                userReportHistory: [...state.userReportHistory.filter(urh => urh.userReportHistoryId !== action.userReportHistoryId), { ...newUserReportHistory, status: action.userReportHistoryStatus }]
            }

        //SET FAVORITE REPORT
        case 'SET_FAVORITE_REPORT_REQUEST':
            return {
                ...state,
                getReportLoading: { ...state.getReportLoading, [action.report.reportId.toString()]: true },
                reports: [...state.reports.flat().filter(r => r.reportId !== action.report.reportId), { ...action.report, isLoading: true }],
                selectedReport: state.selectedReport ? { ...state.selectedReport!, isLoading: true } : null,
                error: undefined,
            }
        case 'SET_FAVORITE_REPORT_SUCCESS':
            return {
                ...state,
                getReportLoading: { ...state.getReportLoading, [action.report.reportId.toString()]: false },
                reports: [...state.reports.flat().map((r) => { return (r.reportId !== action.report.reportId ? { ...r } : { ...action.report, isFavorite: !action.report.isFavorite, isLoading: false }) })],
                selectedReport: state.selectedReport ?{...state.selectedReport,isFavorite:!state.selectedReport.isFavorite}:state.selectedReport,
                error: undefined,
            }
        case 'SET_FAVORITE_REPORT_FAILURE':
            return {
                ...state,
                getReportLoading: { ...state.getReportLoading, [action.report.reportId.toString()]: false },
                reports: [...state.reports.flat().filter(r => r.reportId !== action.report.reportId), { ...action.report, isLoading: false }],
                selectedReport: state.selectedReport ? { ...state.selectedReport, isLoading: false } : null,
                error: action.error
            }
        // SET PIN FAVORITE REPORTS
        case 'SET_PIN_FAVORITE_REPORT':
            return {
                ...state,
                pinFavorites: action.value
            }
        // SET REPORT SAVED
        case 'SET_REPORT_SAVED':
            return {
                ...state,
                saved: action.saved
            }
          // SET REPORT Deleted
          case 'SET_REPORT_DELETED':
            return {
                ...state,
                deleted: action.deleted
            }
        // RESET REPORT
        case 'RESET_REPORT':
            return {
                ...state,
                selectedReport: CreateDefaultReport()
            }
        // SAVE SLICER STATE
        case 'REPORT_SAVE_SLICER_STATE':
            return {
                ...state,
                slicerStates: action.slicerStates
            }
        // SAVE REPORT FILTERS
        case 'REPORT_SAVE_REPORT_FILTERS':
            return {
                ...state,
                reportFilters: action.filters
            }

        // SET FILTER PANE OPEN
        case 'SET_FILTER_PANE_OPEN':
            return {
                ...state,
                filterPaneOpen: action.open
            }

        // SET PAGE FILTERS
        case 'SET_REPORT_PAGE_FILTERS':
            return {
                ...state,
                pageFilters: [...action.filters]
            }

        // APPLY FILTERS
        case 'APPLY_FILTERS':
            return {
                ...state,
                pageFilters: [...[], ...action.filters.map((f) => { return { ...f, applied: f.values.length > 0 && (f.values[0] || f.values[1]) ? true : false } })],
            }

        case 'CLEAR_ALL_FILTERS':
            return {
                ...state,
                pageFilters: CreateReportFilters()
            }

        case 'CLEAR_FILTER':
            return {
                ...state,
                pageFilters: [...[], ...state.pageFilters.map((f) => { return { ...f, applied: action.filterName === f.name ? false : f.applied, values: action.filterName === f.name ? [] : f.values, rawValues: action.filterName === f.name ? undefined : f.rawValues } })],
            }

        // SET FILTER PANE OPEN
        case 'SET_FILE_STORAGE_FILTER_PANE_OPEN':
            return {
                ...state,
                fileStorageFilterPaneOpen: action.open
            }

        // SET FILTERS
        case 'SET_FILE_STORAGE_FILTERS':
            return {
                ...state,
                fileStorageFilters: [...action.filters]
            }

        // APPLY FILTERS
        case 'APPLY_FILE_STORAGE_FILTERS':
            return {
                ...state,
                fileStorageFilters: [...[], ...action.filters.map((f) => { return { ...f, applied: f.values.length > 0 && (f.values[0] || f.values[1]) ? true : false } })],
            }

        case 'CLEAR_FILE_STORAGE_ALL_FILTERS':
            return {
                ...state,
                fileStorageFilters: CreateFileStorageFilters(),
            }

        case 'CLEAR_FILE_STORAGE_FILTER':
            return {
                ...state,
                fileStorageFilters: [...[], ...state.fileStorageFilters.map((f) => { return { ...f, applied: action.filterName === f.name ? false : f.applied, values: action.filterName === f.name ? [] : f.values, rawValues: action.filterName === f.name ? undefined : f.rawValues } })],
            }

        // ADD USER REPORT SHARE
        case 'ADD_USER_REPORT_SHARE_REQUEST':
            return {
                ...state,
                loading: true,
                addUserRptShareLoading: true,
            }
        case 'ADD_USER_REPORT_SHARE_SUCCESS':
            return {
                ...state,
                loading: false,
                addUserRptShareLoading: false,
                userReportHistoryExportShare: action.userReportHistoryExportShare,
            }
        case 'ADD_USER_REPORT_SHARE_FAILURE':
            return {
                ...state,
                loading: false,
                addUserRptShareLoading: false,
                error: action.error
            }
            // GET REPORT SHARED USER
        case 'REPORT_SHARED_USERS_REQUEST':
            return {
                ...state,
                loading: true,
                getSharedReportUsersLoading: true,
            }
        case 'REPORT_SHARED_USERS_SUCCESS':
            return {
                ...state,
                loading: false,
                getSharedReportUsersLoading: false,
                reportSharedUsers: action.reportSharedUsers,
            }
        case 'REPORT_SHARED_USERS_FAILURE':
            return {
                ...state,
                loading: false,
                getSharedReportUsersLoading: false,
                error: action.error
            }
         // IS TILE VIEW
         case 'REPORT_SET_TILE_VIEW':
            return {
                ...state,
                isTileView: action.isTileView
            }

        // CLEAR ALL
        case 'CLEAR-ALL':
            return initialState

        case 'SET_REPORT_LOADING':
            return {
                ...state,
                loadingReport: action.loadingReport
            }
        default:
            return state;
    }
}
export default ReportReducer;